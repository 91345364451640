<template>
  <div>
    <v-dialog
      :value="true"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="900px"
      scrollable
      persistent
    >
      <ProjectIssueForm
        :project-issue="editedProjectIssue"
        :errors="projectIssueValidationErrors"
        :client-view="$isClient()"
        :supplier-view="
          currentUserPermissionInSelectedProject === 'supplier' ||
          currentUserPermissionForEditedIssue === 'supplier'
        "
        :loading="loading"
        @clear:errors="CLEAR_PROJECT_ISSUE_VALIDATION_ERRORS"
        @save="onSave"
        @cancel="goBack"
        @delete="onDelete"
        @update:comment-count="updateCommentCount"
        @delete:attachment="
          deleteProjectIssueAttachment({ issue: editedProjectIssue, attachment: $event })
        "
        @convert-to-user-story="onConvertToUserStory"
      />
    </v-dialog>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import store from '@/store';
import ProjectIssueForm from '@/components/forms/ProjectIssueForm';

export default {
  name: 'EditProjectIssue',

  components: { ProjectIssueForm },

  computed: {
    ...mapState('projectIssues', ['editedProjectIssue', 'projectIssueValidationErrors']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
    ...mapGetters('projectIssues', ['currentUserPermissionForEditedIssue']),

    loading() {
      const { loading } = this.$store.getters;
      return (
        loading[`put:api/project-issues/${this.editedProjectIssue.id}`] ||
        loading[`post:api/project-issues-image/${this.editedProjectIssue.id}`]
      );
    },
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('projectIssues/editProjectIssue', +to.params.projectIssueId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.goBack(to.params.projectId));
      });
  },

  created() {
    if (this.editedProjectIssue.status === 'new') {
      this.markProjectIssueAsSeen(this.editedProjectIssue);
    }
  },

  methods: {
    ...mapMutations('projectIssues', [
      'CLEAR_PROJECT_ISSUE_VALIDATION_ERRORS',
      'UPDATE_PROJECT_ISSUE',
      'SET_EDITED_PROJECT_ISSUE',
    ]),
    ...mapMutations('projectUserStories', ['SET_NEW_PROJECT_USER_STORY']),
    ...mapActions('projectIssues', [
      'markProjectIssueAsSeen',
      'updateProjectIssue',
      'deleteProjectIssueAttachment',
      'deleteProjectIssue',
    ]),

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute(this.$route.params.projectId));
    },

    updateCommentCount(count) {
      const updatedIssue = {
        ...this.editedProjectIssue,
        comments_count: count,
      };

      this.SET_EDITED_PROJECT_ISSUE(updatedIssue);
      this.UPDATE_PROJECT_ISSUE(updatedIssue);
    },

    async onSave(issue) {
      await this.updateProjectIssue(issue);
      this.goBack();
    },

    async onDelete(issue) {
      const res = await this.deleteProjectIssue(issue);
      if (res === 'deleted' && issue.id === +this.$route.params.projectIssueId) {
        this.goBack();
      }
    },

    onConvertToUserStory() {
      this.$router.push({
        name: 'createProjectUserStoryFromIssue',
        params: { projectIssueId: this.editedProjectIssue.id },
      });
    },
  },
};
</script>

<style scoped></style>
